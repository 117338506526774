import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";

import "./Home.scss";

//Components
import Message from "components/MessageBar/MessageBar";
import Calender from "components/Calendar/Calendar";

//Image
import event1 from "imgs/home/event1.png";
import event2 from "imgs/home/event2.png";
import event3 from "imgs/home/event3.png";

//axios
import axiosRequest from "util/axiosConfig";

// Fake data
const fakeEvents = [
  {
    rank: "Yoga (All Levels)",
    content: " Meeting at Terminus at 5pm",
    icon: event1,
  },
  {
    rank: "Heart Health",
    content: " Meeting at FSS 4007 at 4pm ",
    icon: event2,
  },
  {
    rank: "FSS Mental Health",
    content: " Meeting at FSS 1040 at 11am",
    icon: event3,
  },
];

const Home = () => {
  const [events, setEvents] = useState({ data: [], loading: true });
  const [links, setLinks] = useState({ data: [], loading: true });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    //Prevent memory leak
    let isCancelled = false;
    async function fetchData() {
      axiosRequest
        .get("/studentView/links")
        .then((response) => {
          if (!isCancelled) {
            setLinks({ data: response.data, loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);

  // Fetch Event Data is in separate one as will be rerequested upon filters
  useEffect(() => {
    let isCancelled = false;
    // async function fetchData() {
    //   axiosRequest
    //     .get("/studentView/events/?format=json")
    //     .then(() => {
    //       if (!isCancelled) {
    //         setEventData({ data: [], loading: false });
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    // fetchData();
    if (!isCancelled) {
      setEvents({ data: [], loading: false });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      <Message />

      <div className="home">
        <div className="home-events">
          <div className="home__title">What's Happening Today?</div>
          {fakeEvents.map((event, index) => (
            <div className="home-event" key={index}>
              <img src={event.icon} alt="icon" />
              {event.rank}:{event.content}
            </div>
          ))}
        </div>

        <div className="home-links">
          <div className="home__title">Links for you</div>
          {links.data.map((link, index) => {
            return (
              <a className="home-link" key={index} href={link.link}>
                {link.title}
              </a>
            );
          })}
        </div>
        <div className="home-calendar">
          <div className="home__title">My Campus Calendar</div>
          <Calender content={events.data} />
        </div>
      </div>
    </>
  );
};

export default Home;
