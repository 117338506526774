import React from "react";

import live from "imgs/my-fitness/live.png";
import sprites from "imgs/sprites.svg";

import Message from "components/MessageBar/MessageBar";
import { useSelector } from "react-redux";

import "./Livestream.scss";

// import axios from "axios"
import ReactGA from "react-ga";

// Fake Data have to connect backend
const resource = [
  {
    icon: live,
    title_en: "Beginner Barre",
    title_fr: "Barre du Beginner",
    date_en: "Monday",
    date_fr: "Lundi",
    time: "9am - 9:30am",
  },
  {
    icon: live,
    title_en: "HIIT Boxing",
    title_fr: "Boxing de HIIT",
    date_en: "Tuesday",
    date_fr: "Mardi",
    time: "10am - 10:45am",
  },
  {
    icon: live,
    title_en: "Vinyasa Flow",
    title_fr: "Flow Vinyasa",
    date_en: "Wednesday",
    date_fr: "Mercredi",
    time: "11am - 12am",
  },
  {
    icon: live,
    title_en: "Core",
    title_fr: "Le Cor",
    date_en: "Thursday",
    date_fr: "Jeudi",
    time: "5pm - 5:20am",
  },
  {
    icon: live,
    title_en: "Full Body HIIT",
    title_fr: "HIIT du Body Full",
    date_en: "Thursday",
    date_fr: "Jeudi",
    time: "4pm - 4:30pm",
  },
  {
    icon: live,
    title_en: "Leg Sculpt",
    title_fr: "Sculpter la Jambe",
    date_en: "Friday",
    date_fr: "Vendredi",
    time: "12pm - 1am",
  },
];

const LiveStreams = () => {
  const preferEnglish = useSelector((state) => state.student.preferEnglish);

  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <>
      <Message showBackButton={true} />

      <div className="stream">
        <div className="stream__title">
          {preferEnglish
            ? "Livestreams Classes this Week"
            : "Cours en direct cette semaine"}
        </div>
        <div className="stream__resource-list">
          {resource.map((resource, index) => (
            <div className="stream-resource" key={index}>
              <img
                className="stream-resource__img"
                src={resource.icon}
                alt="icon"
              />
              <div className="stream-resource__desc">
                {preferEnglish ? resource.title_en : resource.title_fr}
                <br />
                {preferEnglish ? resource.date_en : resource.date_fr}
                <br />
                {resource.time}
              </div>
              <div className="stream-resource__calendar">
                <svg className="stream-resource__icon">
                  <use href={sprites + "#icon-calendar"}></use>
                </svg>
                <span>
                  {preferEnglish
                    ? "Add to my Calendar"
                    : "Ajouter à mon calendrier"}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LiveStreams;
