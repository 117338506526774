import React, { useState } from "react";

import "./Emergency.scss";

import { useSelector } from "react-redux";

import sprites from "imgs/sprites.svg";

const Emergency = () => {
  const [open, setOpen] = useState(false);
  const preferEnglish = useSelector((state) => state.student.preferEnglish);

  return (
    <>
      <button className="emergency-button" onClick={() => setOpen(true)}>
        {preferEnglish ? "Emergency Support" : "Aide d'Urgence"}
      </button>

      <div
        className="modal-container"
        style={{ visibility: `${open ? "visible" : "hidden"}` }}
      >
        <div className="emergency">
          <svg className="emergency__cross" onClick={() => setOpen(false)}>
            <use href={sprites + "#icon-cross"}></use>
          </svg>

          <div className="emergency__item">
            <div>
              <h3>Crisis Text Line </h3>
              Text HOME to <a href="tel:686868">686868</a> in Canada
            </div>

            <a href="https://www.crisistextline.ca/">
              https://www.crisistextline.ca/
            </a>
            <div>
              Every texter is connected with a Crisis Responder, a real-life
              human being trained to bring texters from a hot moment to a cool
              calm through active listening and collaborative problem-solving.
            </div>
          </div>
          <div className="emergency__item">
            <div>
              <h3>Good2Talk</h3>
              Call <a href="tel:1-866-925-5454">1-866-925-5454</a> or text
              GOOD2TALKON to&nbsp;
              <a href="tel:686868<">686868</a>. (For French, text ALLOJECOUTEON)
            </div>
            <a href="https://good2talk.ca/">https://good2talk.ca/</a>
            <div>
              Good2Talk is a free, confidential service for post-secondary
              students in Ontario, available 24/7/365. By calling
              1-866-925-5454, post-secondary students in Ontario can receive
              professional counselling and information and referrals for mental
              health, addictions and well-being.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Emergency;
