import React from "react";

import { useSelector } from "react-redux";
import { removeFavourite } from "redux/_actions/students";
import { SET_FAV_MINDFULNESS } from "redux/_actions/types";

import "./Favourites.scss";

import sprites from "imgs/sprites.svg";
import ResourceItem from "components/ResourceItem/ResourceItem";

const Favourites = ({ isOpen, close }) => {
  const student = useSelector((state) => state.student);
  const { fav_mindfulness } = student;

  return (
    <div
      className="modal-container"
      style={{ visibility: `${isOpen ? "visible" : "hidden"}` }}
      id={"favourite"}
    >
      <div className="modal favourites">
        <svg className="modal__cross" onClick={() => close()}>
          <use href={sprites + "#icon-cross"}></use>
        </svg>

        <div className="favourites__title">Mindfulness Favourites</div>
        <div className="favourites__list">
          {fav_mindfulness.map((resource, index) => (
            <ResourceItem
              removeFavourite={() =>
                removeFavourite(resource, SET_FAV_MINDFULNESS)
              }
              content={resource}
              key={index}
              isFavourite={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Favourites;
