// axios
import axiosRequest from "util/axiosConfig";
// redux
import store from "redux/_store";

import {
  SET_FAV_RESOURCES,
  SET_FAV_MINDFULNESS,
  SET_FAV_EVENT,
  SET_FAV_LIVESTREAM,
  SET_FAV_ONCAMPUS,
  SET_FAV_ONDEMAND,
  UPDATE_STUDENT,
} from "./types";

export const updateStudent = (student) => {
  store.dispatch((dispatch, getState) => {
    let studentId = getState().student.id;

    studentId = studentId ? studentId : 1; // needed as not all users have student objects yet

    axiosRequest
      .put(`/studentView/students/${studentId}/update`, {
        ...student,
      })
      .then((res) => {
        console.log(res);
        dispatch({
          type: UPDATE_STUDENT,
          student,
        });
      })
      .catch((error) => {
        // TODO: Tell user of failure
        console.log("Could not update student data: ", error);
      });
  });
};

export const addFavourite = (favouriteItem, type) => {
  switch (type) {
    case SET_FAV_RESOURCES:
      store.dispatch(
        addFavouriteAction(favouriteItem, type, "res", "rec_resource")
      );
      break;
    case SET_FAV_MINDFULNESS:
      store.dispatch(
        addFavouriteAction(
          favouriteItem,
          type,
          "mindfulness",
          "fav_mindfulness"
        )
      );
      break;
    case SET_FAV_EVENT:
      store.dispatch(
        addFavouriteAction(favouriteItem, type, "event", "fav_event")
      );
      break;
    case SET_FAV_LIVESTREAM:
      store.dispatch(
        addFavouriteAction(favouriteItem, type, "livestream", "fav_livestream")
      );
      break;
    case SET_FAV_ONCAMPUS:
      store.dispatch(
        addFavouriteAction(favouriteItem, type, "oncampus", "fav_oncampus")
      );
      break;
    case SET_FAV_ONDEMAND:
      store.dispatch(
        addFavouriteAction(favouriteItem, type, "ondemand", "fav_ondemand")
      );
      break;
    default:
      break;
  }
};

const addFavouriteAction = (favouriteItem, type, urlString, bodyAtr) => (
  dispatch,
  getState
) => {
  const { student } = getState();
  let studentId = student.id;
  studentId = studentId ? studentId : 1; // needed as not all users have student objects yet

  const prevFavourites = student[bodyAtr];

  let favourites = [...prevFavourites, favouriteItem];

  // Add it to favourites in Redux Store
  dispatch({
    type,
    favourites,
  });

  // Request to update the favourites by ids in database
  let body = {};
  body[bodyAtr] = favourites.map((item) => item.id);

  axiosRequest
    .put(`/studentView/students/${studentId}/${urlString}/update`, {
      ...body,
    })
    .then(() => {
      // Successful Addition
      console.log("Added ", favouriteItem, " To ", bodyAtr);
    })
    .catch((error) => {
      //Remove Favourite in Redux Store
      dispatch({
        type,
        favourites: [
          ...favourites.filter((checkId) => checkId.id !== favouriteItem.id),
        ],
      });
      // TODO: Display Error to User
      console.log("Could not remove due to server error: ", error);
    });
};

export const removeFavourite = (favouriteItem, type) => {
  switch (type) {
    case SET_FAV_RESOURCES:
      store.dispatch(
        removeFavouriteAction(favouriteItem, type, "res", "rec_resource")
      );
      break;
    case SET_FAV_MINDFULNESS:
      store.dispatch(
        removeFavouriteAction(
          favouriteItem,
          type,
          "mindfulness",
          "fav_mindfulness"
        )
      );
      break;
    case SET_FAV_EVENT:
      store.dispatch(
        removeFavouriteAction(favouriteItem, type, "event", "fav_event")
      );
      break;
    case SET_FAV_LIVESTREAM:
      store.dispatch(
        removeFavouriteAction(
          favouriteItem,
          type,
          "livestream",
          "fav_livestream"
        )
      );
      break;
    case SET_FAV_ONCAMPUS:
      store.dispatch(
        removeFavouriteAction(favouriteItem, type, "oncampus", "fav_oncampus")
      );
      break;
    case SET_FAV_ONDEMAND:
      store.dispatch(
        removeFavouriteAction(favouriteItem, type, "ondemand", "fav_ondemand")
      );
      break;
    default:
      break;
  }
};

const removeFavouriteAction = (favouriteItem, type, urlString, bodyAtr) => (
  dispatch,
  getState
) => {
  const { student } = getState();
  let studentId = student.id;
  studentId = studentId ? studentId : 1; // needed as not all users have student objects yet

  const prevFavourites = student[bodyAtr];

  let favourites = [
    ...prevFavourites.filter((checkItem) => checkItem.id !== favouriteItem.id),
  ];
  // Remove favourite from Redux Store
  dispatch({
    type,
    favourites,
  });
  // Request to update the favourites in database
  let body = {};
  body[bodyAtr] = favourites.map((item) => item.id);
  axiosRequest
    .put(`/studentView/students/${studentId}/${urlString}/update`, {
      ...body,
    })
    .then(() => {
      // Successful removal
      console.log("Removed ", favouriteItem, " From ", bodyAtr);
    })
    .catch((error) => {
      //Add Favourite to Redux Store
      dispatch({
        type,
        favourites: [
          ...prevFavourites.filter(
            (checkItem) => checkItem.id !== favouriteItem.id
          ),
          favouriteItem,
        ],
      });
      // TODO: Display Error to User
      console.log("Could not remove due to server error: ", error);
    });
};
