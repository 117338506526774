import React from "react";
import { useHistory } from "react-router-dom";
import backarrow from "imgs/util/back-arrow.png";

import "./BackButton.scss";

// BackButton for some pages
const BackButton = () => {
  let history = useHistory();
  function handleClick() {
    history.goBack();
  }

  return (
    <button className="back-button" onClick={handleClick}>
      <img className="back-button__arrow" src={backarrow} alt="back arrow" />{" "}
      Back
    </button>
  );
};

export default BackButton;
