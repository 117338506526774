import React, { useEffect, useState } from "react";

import "./Mindfulness.scss";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import ResourceItem from "components/ResourceItem/ResourceItem";
import Message from "components/MessageBar/MessageBar";

import ReactGA from "react-ga";

// axios
import axiosRequest from "util/axiosConfig";

import { useSelector } from "react-redux";
import { addFavourite, removeFavourite } from "redux/_actions/students";
import { SET_FAV_MINDFULNESS } from "redux/_actions/types";

const antIcon = <LoadingOutlined style={{ fontSize: "4vw" }} spin />;

const Mindfulness = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [resources, setResources] = useState({ loading: true, data: [] });
  const favourites = useSelector((state) => state.student.fav_mindfulness);

  useEffect(() => {
    console.log("Mindfulness Data Request Called");
    let isCancelled = false;

    async function fetchData() {
      await axiosRequest
        .get("/studentView/mindfulness/?format=json")
        .then((response) => {
          if (!isCancelled) {
            setResources({ data: response.data, loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      <Message showBackButton={true} />
      <div className="mindfulness">
        <div className="mindfulness__title">Mindfulness Exercises</div>
        <div className="mindfulness__list">
          {resources.loading ? (
            <div className="mindfulness__loading">
              <Spin indicator={antIcon} />
            </div>
          ) : (
            resources.data.map((resource, index) => {
              return (
                <ResourceItem
                  addFavourite={() =>
                    addFavourite(resource, SET_FAV_MINDFULNESS)
                  }
                  removeFavourite={() =>
                    removeFavourite(resource, SET_FAV_MINDFULNESS)
                  }
                  content={resource}
                  key={index}
                  isFavourite={favourites
                    .map((item) => item.id)
                    .includes(resource.id)}
                />
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default Mindfulness;
