import { useState } from "react";

export const useInput = (initValue) => {
  const [value, setValue] = useState(initValue);

  const reset = () => {
    setValue(initValue);
  };

  const bind = {
    value,
    onChange: (e) => {
      setValue(e.target.value);
    },
  };

  return [value, bind, reset];
};

/* Example of How to Use to Organize Input Handling

import { useInput } from 'hooks'

const exampleInput = () => {
   const [name, bindName, resetName] = useInput('')

   const logName = () => {
      console.log(name)
   }

   return (
      <>
         <input type='text' {...bindValue} />
         <button onClick={() => logName()}>Log Name</button>
         <button onClick={() => resetName()}>Reset Name</button>
      </>
   )
}

*/
