import React from "react";
import { useSelector } from "react-redux";

import BackButton from "components/BackButton/BackButton";

import "./MessageBar.scss";

const MessageBar = ({ showBackButton }) => {
  const student = useSelector((state) => state.student);
  const { preferEnglish, preferred_name } = student;

  return (
    <div className="message-bar">
      <span className="message-bar__text">
        {preferEnglish
          ? `Hi ${
              preferred_name === "" ? "Eve" : preferred_name
            }, how can you lessen the resistance in your life today?`
          : `Salut ${
              preferred_name === "" ? "Eve" : preferred_name
            }, comment pouvez-vous réduire la résistance dans votre vie aujourd'hui?`}
      </span>
      {showBackButton && (
        <div className="message-bar__button">
          <BackButton />
        </div>
      )}
    </div>
  );
};

export default MessageBar;
