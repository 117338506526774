import React from "react";

import Message from "components/MessageBar/MessageBar";
import "./OnCampus.scss";

import ReactGA from "react-ga";

// Due to COVID, all functionality has been removed from this page
// Some work was done here by previous interns, which can be found in the git log
const OnCampus = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      <Message showBackButton={true} />

      <div className="campus">
        <div className="campus__title">On-Campus Classes</div>
        <div className="campus__status">Campus Temporarily Closed</div>
      </div>
    </>
  );
};
export default OnCampus;
