import React from "react";
import { Link } from "react-router-dom";

import Message from "components/MessageBar/MessageBar";

import backgroundBlue from "imgs/mental-health/background-blue.svg";
import backgroundGrey from "imgs/mental-health/background-grey.svg";
import articles from "imgs/mental-health/articles.png";
import scale from "imgs/mental-health/scale.png";

import wwLogo from "imgs/navbar/ww-logo.png";

import "./MentalHealth.scss";

import ReactGA from "react-ga";

const tabs = [
  {
    title: "My Recommended Resources",
    desc: `Unsure where to start? Take a quick and anonymous assessment to find resources right for you.`,
    tmp: "*A demo video will be featured for this test",
    btn: "Start Survey",
    back: backgroundBlue,
    icon: wwLogo,
    link: "mental-health",
  },
  {
    title: "Browse All Resources",
    desc: `View online, on-campus, and off campus mental health resources. Filterable for your needs and preferences.`,
    tmp: "*Filters limited for this test",
    btn: "View All",
    icon: articles,
    link: "mental-health/all-resource",
  },
  {
    title: "Mindfulness Exercises",
    desc: "Browse through guided meditation and wellness articles.",
    btn: "View All",
    tmp: "",
    back: backgroundGrey,
    icon: scale,
    link: "mental-health/mindfulness-resource",
  },
];

const MentalHealth = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="mental-health">
      <Message />

      {tabs.map((tab, index) => (
        <div
          className="tab-container"
          style={{ backgroundImage: `url(${tab.back})` }}
          id={tab.title}
          key={index}
        >
          <div className="tab__info">
            <img className="tab__img" src={tab.icon} alt={tab.title} />
            <div className="tab__title">{tab.title}</div>
            <div className="tab__description">
              {tab.desc}
              <p>{tab.tmp}</p>
            </div>
          </div>

          <Link to={tab.link}>
            <button
              className="tab__button"
              onClick={() => {
                if (index === 0) {
                  window.location.href =
                    "https://www.youtube.com/watch?v=qGg0sC-PT8Y";
                }
              }}
            >
              {tab.btn}
            </button>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default MentalHealth;
