import axios from "axios";
import Cookies from "js-cookie";

const axiosRequest = axios.create({
  baseURL: "https://wellness-world-api-test.herokuapp.com",
  responseType: "json",
});

axiosRequest.interceptors.request.use((config) => {
  config.headers.Authorization = Cookies.get("Authorization");
  config.headers["Content-Type"] = "application/json";
  return config;
});

export default axiosRequest;
