import React from "react";

import {Modal, Button} from 'antd';
import { HeartOutlined, SendOutlined } from '@ant-design/icons';

import "./Event.scss";

// images
/*import icoArt from "imgs/events/art.png";
import icoClub from "imgs/events/club.png";
import icoNet from "imgs/events/network.png";
import icoHealth from "imgs/events/health.png";*/


// Will have to style it and add functionality:
//     - Add to Calendar
//     - Add to favourites  -> I will look into this functionality -Ben
//     - display time, date, title, desc

const Event = ({event, setEventOpen}) => {
    const {title, desc} = event; // extract information from the event

    //const [fav, setFav] = useState()

    const handleClose = () => {
        setEventOpen(false)
    }

    return (
        <div className="event modal">
            <Modal
                title={title}
                centered
                visible={true}
                onCancel={handleClose}
                maskClosable={true} // close dialog when outside is clicked
                destroyOnClose={true} // unmount component after close
                closable={true}
                footer={null}
            >
                <div className="event">
                    <div className="event__desc"> { desc } </div>
                    <div className="event__button">
                        {<Button block icon={<HeartOutlined />} onClick={() => console.log('e')}>
                            Save to Favourites
                        </Button>}
                        <Button block
                            icon={<SendOutlined/>}
                        >
                            Send to friend
                        </Button>
                    </div>
                </div>

            </Modal>
        </div>
    );
};

export default Event;
