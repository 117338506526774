import React from "react";

import sprites from "imgs/sprites.svg";
import wwLogo from "imgs/navbar/ww-logo.png";

import "./Profile.scss";

import { useInput } from "hooks";
import { useSelector } from "react-redux";
import { updateStudent } from "redux/_actions/students";

const Profile = ({ isOpen, close }) => {
  const student = useSelector((state) => state.student);

  const [preferred_name, bindName] = useInput(student.preferred_name);
  const [study_year, bindYear] = useInput(student.study_year);

  return (
    <div
      className="modal-container"
      style={{ visibility: `${isOpen ? "visible" : "hidden"}` }}
      id={"profile"}
    >
      <div className="modal profile">
        <svg className="modal__cross" onClick={() => close()}>
          <use href={sprites + "#icon-cross"}></use>
        </svg>

        <img
          className="profile__logo"
          src={wwLogo}
          alt={"Wellness World Logo"}
        />

        <div className="profile__inputs">
          <div>Email</div>
          <span>{student.email}</span>

          <div>Preferred Name</div>
          <input {...bindName} />

          <div>Year of Study</div>
          <select {...bindYear}>
            <option value="1">First Year</option>
            <option value="2">Second Year</option>
            <option value="3">Third Year</option>
            <option value="4">Fourth Year</option>
            <option value="5">Fifth Year +</option>
          </select>
        </div>

        <button
          className="profile__button"
          onClick={() => updateStudent({ preferred_name, study_year })}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Profile;
