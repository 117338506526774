import React, { useState } from "react";
import { Link } from "react-router-dom";
import wwLogo from "imgs/navbar/ww-logo.png";
import WW from "imgs/navbar/ww-logo-name.png";
import ReactGA from "react-ga";

import "./Onboarding.scss";

//axios
import axiosRequest from "util/axiosConfig";

const Register = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  let [password, setPassword] = useState("");
  let [username, setUsername] = useState("");
  let [firstName, setFirstName] = useState("");
  // let [lastName] = useState("Z");

  let handleSubmit = function () {
    const data = {
      password: password,
      username: username,
      // first_name: firstName,
      // last_name: lastName,
    };

    axiosRequest
      .post("/studentView/register/", data)
      .then((value) => {
        console.log(value);
        alert("Success");
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.username) {
          alert(error.response.data.username[0]);
        } else if (error.response.data.password) {
          alert(error.response.data.password[0]);
        } else {
          alert("Register Error(Please use your University Eamil to Register)");
        }
      });
  };

  return (
    <div className="onboarding">
      <div className="onboarding__logo-container">
        <img src={wwLogo} alt={"logo"} />
        <img src={WW} alt={"logo"} />
      </div>

      <div className="onboarding__content">
        <div className="onboarding__link">
          Already have an account?&nbsp;&nbsp;
          <Link to={"/login"}>Login</Link>
        </div>
        <div className="onboarding__title">Please Register</div>
        <form className="onboarding__form">
          <div className="onboarding__inputs">
            <span>Name</span>
            <input
              type={"text"}
              placeholder="Your Preferred Name"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              value={firstName}
            />
            <span>Email</span>
            <input
              type={"text"}
              placeholder="Your University Email"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              value={username}
            />

            <span>Password</span>
            <input
              type={"password"}
              autoComplete="on"
              placeholder="Must Be 4-30 Characters"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </div>

          <div
            className="onboarding__button"
            type="primary"
            shape="circle"
            onClick={handleSubmit}
          >
            REGISTER
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
