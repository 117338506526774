// AUTH ACTIONS
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// STUDENT ACTIONS
export const SET_STUDENT = "SET_STUDENT";
export const RESET_STUDENT = "RESET_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_FAV_RESOURCES = "SET_FAV_RESOURCES";
export const SET_FAV_MINDFULNESS = "SET_FAV_MINDFULNESS";
export const SET_FAV_EVENT = "SET_FAV_EVENT";
export const SET_FAV_LIVESTREAM = "SET_FAV_LIVESTREAM";
export const SET_FAV_ONDEMAND = "SET_FAV_ONDEMAND";
export const SET_FAV_ONCAMPUS = "SET_FAV_ONCAMPUS";
