import React from "react";
import { Link } from "react-router-dom";

import group from "imgs/my-fitness/group.png";
import workout from "imgs/my-fitness/workout.png";
import youtube from "imgs/my-fitness/youtube.png";

import Message from "components/MessageBar/MessageBar";
import ReactGA from "react-ga";

import "./MyFitness.scss";

const resource = [
  {
    id: 1,
    title: "Livestream Classes",
    icon: youtube,
    description: "Workout real-time with an instructor, wherever you are",
    link: "/fitness/live-streams",
  },
  {
    id: 2,
    title: "On-Demand Classes",
    icon: workout,
    description:
      "Explore over 50 pre-recorded workout classes from boxing, yoga, pilates and more",
    link: "/fitness/on-demand",
  },
  {
    id: 3,
    title: "On-Campus Classes",
    icon: group,
    description: "Check out what fitness classes are available on campus",
    link: "/fitness/on-campus",
  },
];

const MyFitness = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      <Message />
      <div className="fitness">
        <div className="fitness__title">
          Choose how you feel like moving today
        </div>
        <div className="fitness__blocks">
          {resource.map((resource, index) => (
            <Link
              className="fitness-block"
              to={resource.link}
              key={index}
              id={resource.id}
            >
              <div className="fitness-block__title">{resource.title}</div>
              <div className="fitness-block__img">
                <img src={resource.icon} alt="Not Found" />
              </div>
              <p className="fitness-block__desc">{resource.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default MyFitness;
