import React, { useState } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import Event from "components/Event/Event";

import "./Calendar.scss";

// Documentation for FullCalendar: https://fullcalendar.io/docs

// This component along with its accompanying Event component need to be completed
// For now use the fakeEvent data which can be tweaked a bit for your use and then hopefully the backend guys can reflect its structure in the backend

const fakeEvents = [
  // Confirm Structure of Data so we can converse with backend guys to have it updated
  {
    title: "BasketBall Game",
    desc: "This is a fitness event",
    id: 1,
    date: `2020-08-06`,
    // Date needed in this format for the Calendar component to know where to put it
    // Should think of possibility of time of day ie. (1pm - 3pm)
    type: "Fitness",
    link: "#",
  },
  {
    title: "Art Event",
    desc: "This is a art event",
    id: 2,
    date: `2020-08-06`,
    type: "Art",
    link: "#",
  },
  {
    title: "Club Event",
    desc: "This is a club event",
    id: 3,
    date: `2020-08-06`,
    type: "Club",
    link: "#",
  },
  {
    title: "Live At-Home Zumba",
    desc: "This is a fitness event",
    id: 4,
    date: `2020-08-06`,
    type: "Fitness",
    link: "#",
  },
  {
    title: "Meditation",
    desc: "This is a meditation event",
    id: 5,
    date: `2020-08-07`,
    type: "Health",
    link: "#",
  },
  {
    title: "Club Event",
    desc: "This is a club event",
    id: 6,
    date: `2020-08-12`,
    type: "Club",
    link: "#",
  },
  {
    title: "Job Fair Event",
    desc: "This is a job fair event",
    id: 7,
    date: `2020-08-14`,
    type: "Networking",
    link: "#",
  },
];

const Calendar = () => {
  const [eventOpen, setEventOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({}); // this could be used to track

  const handleEventClick = (event) => {
    console.log("Clicked Event All Data: ", event);
    let { title, publicId } = event.event._def;
    let { desc, type, link } = event.event._def.extendedProps;
    // extendedProps are any information in your event object that the Calendar does
    //not usually need but makes it so we can pass extra data to this function

    // Have to find a way to extract the date from the event
    let eventInfo = { title, id: publicId, desc, type, link };
    console.log("Clicked Event Specific Data: ", eventInfo);

    // Open Pop Up
    // setSelectedEvent to the clicked event data
    setSelectedEvent(eventInfo);
    // set state of event open
    setEventOpen(true);
  };

  // Must be able to filter in terms of type/tag
  //       - This could be done here after getting all events and using .filter on javascript array
  //             - ie. events.filter({type: 'Fitness'})
  //       - Or could copy how they have it done in Browse component where they rerequest the data with the filter url set

  // Work on styling of the calendar
  //    - different colored events based on type/tag - Docs: https://fullcalendar.io/docs/event-display
  //    - find out why icons for switching months are not appearing
  //    - Fix color of days of week and dates
  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek'
        }}
        initialView="dayGridMonth"
        eventClick={handleEventClick}
        events={fakeEvents} // Sets the events to be shown to the array of events
        dayMaxEvents={true}
        themeSystem="standard"
      />
      {/* Event only visible if eventOpen set to true */}
      {eventOpen && (
        <Event
          event={selectedEvent}
          setEventOpen={setEventOpen}
          key={selectedEvent.id}
        />
      )}
    </>
  );
};

export default Calendar