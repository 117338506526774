import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";

import wwLogoName from "imgs/navbar/ww-logo-name.png";
import unionLogo from "imgs/navbar/union-logo.png";
import profile from "imgs/navbar/profile.png";

import "./NavBar.scss";
import "./Modals.scss";

import Emergency from "./Emergency/Emergency";
import Profile from "./Profile/Profile";
import Favourite from "./Favourites/Favourites";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { connect } from "react-redux";
import { logout } from "redux/_actions/auth";

import { useSelector, useDispatch } from "react-redux";
import { SET_LANGUAGE } from "redux/_actions/types";

import { Tooltip } from "antd";

const Nav = withRouter((props) => <NavBar {...props} />);

const NavBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const preferEnglish = useSelector((state) => state.student.preferEnglish);

  const [profileOpen, setProfileOpen] = useState(false);
  const [favouriteOpen, setFavouriteOpen] = useState(false);

  // French translations done by Ben via Google, MUST be replaced
  const tabs = [
    {
      path: "/home",
      text_en: "My Community",
      text_fr: "Mes Communauté",
    },
    {
      path: "/mental-health",
      text_en: "My Mental Health",
      text_fr: "Mes Santé Mentale",
    },
    {
      path: "/fitness",
      text_en: "My Physical Health",
      text_fr: "Mes Santé Physique",
    },
  ];

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openFavourites = () => {
    setFavouriteOpen(true);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const switchLanguage = () => {
    // Should send request to update the students preference in db
    dispatch({
      type: SET_LANGUAGE,
      preferEnglish: !preferEnglish,
    });
  };

  return (
    <div className="navbar">
      <Link className="navbar__logo-container" to="/home">
        <img className="navbar__logo-name" src={wwLogoName} alt="logo" />
        <img className="navbar__logo-union" src={unionLogo} alt="logo" />
      </Link>

      <div className="navbar__links">
        {tabs.map((tab, index) =>
          RegExp(tab.path).test(props.location.pathname) ? (
            <Link
              className="navbar__link navbar__link--active"
              key={index}
              to={tab.path}
            >
              {preferEnglish ? tab.text_en : tab.text_fr}
            </Link>
          ) : (
            <Link className="navbar__link" key={index} to={tab.path}>
              {preferEnglish ? tab.text_en : tab.text_fr}
            </Link>
          )
        )}
      </div>

      <Emergency />

      <Tooltip
        placement="bottom"
        title={preferEnglish ? "Passer au français" : "Switch to English"}
      >
        <div className="navbar__language" onClick={switchLanguage}>
          {preferEnglish ? "fr" : "en"}
        </div>
      </Tooltip>

      <img
        className="navbar__profile"
        src={profile}
        alt="Menu"
        onClick={openMenu}
      />

      <Menu
        className="nav-menu"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            closeMenu();
            setProfileOpen(true);
          }}
        >
          {preferEnglish ? "Profile" : "Profil"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            openFavourites();
          }}
        >
          {preferEnglish ? "Favourites" : "Favoris"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            props.logout();
          }}
        >
          {preferEnglish ? "Logout" : "Fermer la Session"}
        </MenuItem>
      </Menu>

      <Profile isOpen={profileOpen} close={() => setProfileOpen(false)} />
      <Favourite isOpen={favouriteOpen} close={() => setFavouriteOpen(false)} />
    </div>
  );
};

export default connect(null, { logout })(Nav);
