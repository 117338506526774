import React from "react";

import "./ResourceItem.scss";

import sprites from "imgs/sprites.svg";

const ResourceItem = (props) => {
  const { isFavourite, addFavourite, removeFavourite, isResource } = props;
  const { title, link, calendar_link } = props.content;
  // const { title, desc, link, calendar_link, id } = props.content;

  const handleFavourite = () => {
    if (isFavourite) removeFavourite();
    else addFavourite();
  };

  return (
    <div className="resource">
      <div className="resource__title">{title}</div>
      <div className="resource__desc">
        This is a fake description of a real resource. This paragraph should be
        replaced with the description loaded from the backend
      </div>

      <div className="resource__options">
        <a
          className="resource__option"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg className="resource__icon">
            <use href={sprites + "#icon-web"}></use>
          </svg>
          <span>Learn More</span>
        </a>

        {!isResource && (
          <a
            className="resource__option"
            href={`https://${calendar_link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="resource__icon">
              <use href={sprites + "#icon-calendar-blue"}></use>
            </svg>
            <span>Add to Google Calendar</span>
          </a>
        )}

        <div className="resource__option" onClick={handleFavourite}>
          <svg
            className="resource__heart resource__icon"
            style={{ fill: isFavourite ? "#3f88ae" : "none" }}
          >
            <use href={sprites + "#icon-heart"}></use>
          </svg>
          <span>
            {isFavourite ? "Remove From Favourites" : "Add to Favourites"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResourceItem;
