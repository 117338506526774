import {
  SET_LANGUAGE,
  SET_STUDENT,
  RESET_STUDENT,
  UPDATE_STUDENT,
  SET_FAV_RESOURCES,
  SET_FAV_EVENT,
  SET_FAV_LIVESTREAM,
  SET_FAV_ONCAMPUS,
  SET_FAV_ONDEMAND,
  SET_FAV_MINDFULNESS,
} from "../_actions/types.js";

const initialState = {
  preferEnglish: true,
  preferred_name: "",
  email: "",
  study_year: "0",
  university: { id: null, name: "", campus: "" },
  id: null,
  rec_resource: [],
  fav_mindfulness: [],
  fav_event: [],
  fav_livestreams: [],
  fav_ondemand: [],
  fav_oncampus: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_STUDENT:
      return {
        ...state,
        ...action.student,
      };
    case RESET_STUDENT:
      return {
        ...initialState,
      };
    case UPDATE_STUDENT:
      return {
        ...state,
        ...action.student,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        preferEnglish: action.preferEnglish,
      };
    case SET_FAV_RESOURCES:
      return {
        ...state,
        rec_resource: action.favourites,
      };
    case SET_FAV_MINDFULNESS:
      return {
        ...state,
        fav_mindfulness: action.favourites,
      };
    case SET_FAV_EVENT:
      return {
        ...state,
        fav_event: action.favourites,
      };
    case SET_FAV_LIVESTREAM:
      return {
        ...state,
        fav_livestreams: action.favourites,
      };
    case SET_FAV_ONCAMPUS:
      return {
        ...state,
        fav_oncampus: action.favourites,
      };
    case SET_FAV_ONDEMAND:
      return {
        ...state,
        fav_ondemand: action.favourites,
      };
    default:
      return state;
  }
}
