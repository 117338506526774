import React, { useState, useEffect, Fragment } from "react";

import Message from "components/MessageBar/MessageBar";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import ReactGA from "react-ga";
import ResourceItem from "components/ResourceItem/ResourceItem";

import "./Browse.scss";

// Axios
import axiosRequest from "util/axiosConfig";

import { useSelector } from "react-redux";
import { addFavourite, removeFavourite } from "redux/_actions/students";
import { SET_FAV_RESOURCES } from "redux/_actions/types";

const antIcon = <LoadingOutlined style={{ fontSize: "4vw" }} spin />;

//Options
const Options = [
  { label: "On Campus", id: "On_Campus", color: "green" },
  { label: "Off Campus", id: "Off_Campus", color: "green" },
  // { label: "Online", id: "Online", color: "green" },
  { label: "Phone", id: "Phone", color: "green" },
  { label: "Peer to Peer", id: "Peer_To_Peer", color: "pink" },
  { label: "Individual", id: "Individual", color: "pink" },
  { label: "Group", id: "Group", color: "pink" },
  // { label: "Training", id: "Training", color: "pink" },
  // { label: "LGBTQ+", id: "LGBTQ", color: "purple" },
  { label: "International", id: "International", color: "purple" },
  // { label: "Minority", id: "Minority", color: "purple" },
  { label: "Womxn", id: "Womxn", color: "purple" },
  // { label: "Spirtual", id: "Spirtual", color: "purple" },
  // { label: "Indigenous", id: "Indigenous", color: "purple" },
];

const Browse = () => {
  // let history = useHistory();
  const [filterLink, setFilterLink] = useState([]);
  const [dev, setDev] = useState(true);

  const [resources, setResources] = useState([]);

  const favourites = useSelector((state) => state.student.fav_resource);

  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    console.log("Browse Data Request Called");
    // Prevent Memory Leak
    let isCancelled = false;

    async function fetchData() {
      await axiosRequest
        .get(
          "/studentView/res/" +
            (filterLink.length ? filterLink + "/" : filterLink) +
            "?format=json"
        )

        .then((response) => {
          if (!isCancelled) {
            setResources(response.data);
          }
          document.getElementById("loading").style.display = "none";
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [dev, filterLink]);

  const handleOnChange = function (e) {
    let index = filterLink.findIndex((item) => item === e.target.value);
    let tmp = [];
    if (index !== -1) {
      filterLink.splice(index, 1);
      tmp = filterLink;
      setFilterLink(tmp);
      setDev(!dev);
    } else {
      filterLink.push(e.target.value);
      tmp = filterLink;
      setFilterLink(filterLink);
      setDev(!dev);
    }
  };

  return (
    <>
      <Message showBackButton={true} />

      <div className="browse">
        <form className="browse__options">
          {Options.map((option, index) => {
            let title = "";
            if (index === 0) {
              title = "Type";
            } else if (index === 3) {
              title = "Method";
            } else if (index === 6) {
              title = "Groups";
            }
            return (
              <Fragment key={index}>
                {title ? <div className="options-title">{title}</div> : null}
                <div className="option">
                  <input
                    className={`option__input option__input--${option.color}`}
                    type="checkbox"
                    name={option.label}
                    value={option.id}
                    id={option.id}
                    onChange={handleOnChange}
                  />
                  <label htmlFor={option.id} className="option__circle" />
                  <div className="option__label">{option.label}</div>
                </div>
              </Fragment>
            );
          })}
        </form>

        <div className="browse__resource-list">
          <div className="browse__title">Browse All Resources</div>
          <div className="browse__loading" id="loading">
            <Spin indicator={antIcon} />
          </div>

          {resources.map((resource) => (
            <ResourceItem
              isResource
              content={resource}
              key={resource.id}
              addFavourite={() => addFavourite(resource, SET_FAV_RESOURCES)}
              removeFavourite={() =>
                removeFavourite(resource, SET_FAV_RESOURCES)
              }
              isFavourite={favourites
                .map((item) => item.id)
                .includes(resource.id)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Browse;
