import React, { useEffect } from "react";
//Google Analytics for visitor record
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//Util
import PrivateRoute from "util/privateRouter";
import ScrollToTop from "util/scrollToTop";

//Components
import NavBar from "components/NavBar/NavBar";

//pages
import Login from "pages/LoginSystem/Login";
import Register from "pages/LoginSystem/Register";
import ResetPassword from "pages/LoginSystem/ResetPassword";

import Home from "pages/Home/Home";

import MentalHealth from "pages/MentalHealth/MentalHealth";
import BrowseAllResources from "pages/MentalHealth/Browse/Browse";
import MindfulnessResource from "pages/MentalHealth/Mindfulness/Mindfulness";

import Fitness from "pages/MyFitness/MyFitness";
import LiveStreams from "pages/MyFitness/Livestream/Livestream";
import OnCampus from "pages/MyFitness/OnCampus/OnCampus";
import OnDemand from "pages/MyFitness/OnDemand/OnDemand";

// Style for Ant Design
import "antd/dist/antd.css";

//Redux
import { connect } from "react-redux";
import store from "./redux/_store";
import { loadUser } from "./redux/_actions/auth";

// HotJar
// import { hotjar } from "react-hotjar";

//cookie
import { useCookies } from "react-cookie";

const App = () => {
  ReactGA.initialize("UA-172079310-1");
  // hotjar.initialize(1933298, 6);

  const [cookie] = useCookies(["Authorization"]);

  useEffect(function () {
    store.dispatch(loadUser(cookie.Authorization));
  });

  return (
    <>
      <Router>
        {/* When users jump to another page, it will scroll to the top */}
        <ScrollToTop />

        <div className="app">
          <Switch>
            {/* Login system */}
            <Route exact path={"/login"}>
              <Login />
            </Route>
            <Route exct path={"/register"}>
              <Register />
            </Route>
            <Route exct path={"/reset-password"}>
              <ResetPassword />
            </Route>

            {/* Home */}
            <PrivateRoute exact path="/">
              <Redirect to="/home"></Redirect>
            </PrivateRoute>
            <PrivateRoute exact path="/home">
              <NavBar />
              <Home />
            </PrivateRoute>

            {/* Mental Health  */}
            <PrivateRoute exact path="/mental-health">
              <NavBar />
              <MentalHealth />
            </PrivateRoute>
            <PrivateRoute exact path="/mental-health/all-resource">
              <NavBar />
              <BrowseAllResources />
            </PrivateRoute>
            <PrivateRoute exact path="/mental-health/mindfulness-resource">
              <NavBar />
              <MindfulnessResource />
            </PrivateRoute>

            {/* Fitnesss */}
            <PrivateRoute exact path="/fitness">
              <NavBar />
              <Fitness />
            </PrivateRoute>
            <PrivateRoute exact path="/fitness/live-streams">
              <NavBar />
              <LiveStreams />
            </PrivateRoute>
            <PrivateRoute exact path="/fitness/on-campus">
              <NavBar />
              <OnCampus />
            </PrivateRoute>
            <PrivateRoute exact path="/fitness/on-demand">
              <NavBar />
              <OnDemand />
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    </>
  );
};

export default connect(null, null)(App);
