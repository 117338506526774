import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
//Google Analytic
import ReactGA from "react-ga";
//Image
import wwLogo from "imgs/navbar/ww-logo.png";
import WW from "imgs/navbar/ww-logo-name.png";
//Redux
import { connect } from "react-redux";
import { login } from "redux/_actions/auth";

import "./Onboarding.scss";

const Login = (props) => {
  //Google Analystics
  ReactGA.pageview(window.location.pathname + window.location.search);

  const history = useHistory();
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");

  const handleSubmit = function () {
    props.login(inputEmail, inputPassword);
    return null;
  };

  const handleTyping = function () {
    let borders = document.getElementsByClassName("borders");
    borders[0].style = "border-color: #babbbf";
    borders[1].style = "border-color: #babbbf";
    document.getElementById("warn").style = "display: none";
  };

  //Press "Return" to Submit
  const EnterPress = function (e) {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  //Quick login by typing Enter
  useEffect(() => {
    document.addEventListener("keypress", EnterPress);
    return () => {
      document.removeEventListener("keypress", EnterPress);
    };
  });

  //Authenitcation check
  useEffect(
    function () {
      function GoHomePage() {
        history.push("/home");
      }
      if (props.isAuthenticated) {
        GoHomePage();
      }
    },
    [props.isAuthenticated, history]
  ); //????history??

  return (
    <div className="onboarding">
      <div className="onboarding__logo-container">
        <img src={wwLogo} alt={"logo"} />
        <img src={WW} alt={"logo"} />
      </div>

      <div className="onboarding__content">
        <div className="onboarding__link">
          Not yet signed up?&nbsp;&nbsp;
          <Link to={"/register"}>Create Account</Link>
        </div>

        <div className="onboarding__title">Welcome Back</div>
        <form className="onboarding__form">
          <div className="onboarding__inputs">
            <span>Email</span>
            <input
              type={"text"}
              className={"borders"}
              placeholder="Enter Your University email"
              onChange={(e) => {
                setInputEmail(e.target.value);
                handleTyping();
              }}
              value={inputEmail}
            />

            <span>Password</span>
            <input
              className="borders"
              type="password"
              placeholder="Enter Your Password"
              onChange={(e) => {
                setInputPassword(e.target.value);
                handleTyping();
              }}
              value={inputPassword}
              autoComplete="on"
            />
            <div className="onboarding__warning" id="warn">
              Email or Password is incorrect. Please try again
            </div>
          </div>
          <div className="onboarding__forgot">
            <Link to="/reset-password">
              <span>Forgot Password?</span>
            </Link>
          </div>

          <div
            className="onboarding__button"
            type="primary"
            shape="circle"
            onClick={handleSubmit}
          >
            ENTER
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loginFail: state.auth.loginFail,
});

export default connect(mapStateToProps, { login })(Login);
