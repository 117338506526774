import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//Google Analytic
import ReactGA from "react-ga";
//Image
import wwLogo from "imgs/navbar/ww-logo.png";
import WW from "imgs/navbar/ww-logo-name.png";

//axios
import axiosRequest from "util/axiosConfig";

import "./Onboarding.scss";

const ResetPassword = (props) => {
  //Google Analystics
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [inputEmail, setInputEmail] = useState("");

  // This functionality has a bug. When u focus on the input, if u press Enter, it will refresh the screen rather submit the form
  const handleSubmit = function () {
    const data = {
      email: inputEmail,
    };

    axiosRequest.get(`/studentView/finduser/${inputEmail}`).then((value) => {
      //If the user exist it will return Object. Otherwise, it will return []
      if (!value.data[0]) {
        let borders = document.getElementsByClassName("borders");
        borders[0].style = "border-color: red";
        document.getElementById("warn").style = "display: block";
      } else {
        axiosRequest.post("/auth/password/reset/", data).then((value) => {
          alert("Succuss");
        });
      }
    });

    return null;
  };

  //Press "Return" to Submit
  const EnterPress = function (e) {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", EnterPress);
    return () => {
      document.removeEventListener("keypress", EnterPress);
    };
  });

  //delete red warning
  const handleTyping = function () {
    let borders = document.getElementsByClassName("borders");
    borders[0].style = "border-color: #babbbf";
    document.getElementById("warn").style = "display: none";
  };

  return (
    <div className="onboarding">
      <div className="onboarding__logo-container">
        <img src={wwLogo} alt={"logo"} />
        <img src={WW} alt={"logo"} />
      </div>
      <div className="onboarding__content">
        <div className="onboarding__link">
          Back to Login Page?&nbsp;&nbsp;
          <Link to={"/login"}>Login</Link>
        </div>
        <div className="onboarding__title">Reset Password</div>
        <form className="onboarding__form">
          <div className="onboarding__inputs">
            <span>Email</span>
            <input
              type={"text"}
              className={"borders"}
              placeholder="Enter Your University email"
              onChange={(e) => {
                setInputEmail(e.target.value);
                handleTyping();
              }}
              value={inputEmail}
              autoComplete="on"
            />

            <div className="onboarding__warning" id="warn">
              Email does not exist. Please try again
            </div>
          </div>

          <div
            className="onboarding__button"
            type="primary"
            shape="circle"
            onClick={handleSubmit}
          >
            ENTER
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
