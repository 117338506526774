//axios
import axiosRequest from "util/axiosConfig";

import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  SET_STUDENT,
  RESET_STUDENT,
} from "./types";

//CHECK TOKEN && LOAD USER
export const loadUser = (token) => (dispatch) => {
  //USER Loading
  dispatch({ type: USER_LOADING });

  //Verify the token
  axiosRequest
    // TODO: GET CORRECT STUDENT
    .get("/studentView/student", {
      // this Student id should be the student id that is connected to the User who signed in
      // Should pull their name, year, language preference and email
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then((res) => {
      console.log("Authenticated");
      // Set Student Data in Redux
      let studentData = res.data[0];

      console.log("Student: ", studentData);
      // Cleanse Student Data
      studentData = {
        ...studentData,
        preferEnglish: true,
      };
      let { birthday, user, ...student } = studentData;

      dispatch({
        type: SET_STUDENT,
        student,
      });
      dispatch({
        type: USER_LOADED,
      });
    })
    .catch((err) => {
      document.cookie =
        "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

// LOGIN
export const login = (username, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json ",
    },
  };

  //Request Body
  const body = {
    username: username,
    password: password,
  };
  axiosRequest
    .post("/auth/login/", body, config)
    .then((res) => {
      document.cookie = `Authorization=Token ${res.data.key}`;
      dispatch({
        type: LOGIN_SUCCESS,
      });

      // Access Student Data
      // TODO: GET CORRECT STUDENT
      axiosRequest
        .get("/studentView/student")
        .then((res) => {
          // Set Student Data in Redux
          let studentData = res.data[0];
          console.log("Student: ", studentData);
          // Cleanse Data to match front end
          studentData = {
            ...studentData,
            preferEnglish: true,
          };
          let { birthday, user, ...student } = studentData;

          dispatch({
            type: SET_STUDENT,
            student,
          });
        })
        .catch((err) => {
          console.log("Error loading student");
        });
    })
    .catch((err) => {
      loginFail();
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};

// DOM Reaction for Login Fail
const loginFail = function () {
  let borders = document.getElementsByClassName("borders");
  borders[0].style = "border-color: red";
  borders[1].style = "border-color: red";
  document.getElementById("warn").style = "display: block";
};

// LOGOUT
export const logout = () => (dispatch) => {
  document.cookie = "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  dispatch({
    type: LOGOUT_SUCCESS,
  });
  dispatch({
    type: RESET_STUDENT,
  });
};
