import React, { useEffect, useState } from "react";
import Message from "components/MessageBar/MessageBar";
import ReactGA from "react-ga";

import "./OnDemand.scss";

import sprites from "imgs/sprites.svg";

import { useSelector } from "react-redux";
import { addFavourite, removeFavourite } from "redux/_actions/students";
import { SET_FAV_ONDEMAND } from "redux/_actions/types";

//axios
import axiosRequest from "util/axiosConfig";

const OnDemand = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [resources, setResources] = useState([]);
  const favourites = useSelector((state) => state.student.fav_ondemand);

  useEffect(() => {
    console.log("OnDemand Data Request Called");

    //Prevent from memory leak
    let isCancelled = false;

    async function fetchData() {
      await axiosRequest
        .get("/studentView/ondemand/")
        .then((response) => {
          if (!isCancelled) {
            setResources(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    fetchData();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      <Message showBackButton={true} />
      <div className="demand">
        <div className="demand__title">On-Demand Class</div>

        <div className="demand__resource-list">
          {resources.map((resource, index) => {
            let isFavourite = favourites
              .map((item) => item.id)
              .includes(resource.id);
            return (
              <div className="demand-resource" href={resource.link} key={index}>
                <img
                  className="demand-resource__thumbnail"
                  src={resource.image}
                  alt="what"
                />
                <div className="demand-resource__title">{resource.title}</div>

                <div
                  className="demand-resource__favourite"
                  onClick={
                    isFavourite
                      ? () => removeFavourite(resource, SET_FAV_ONDEMAND)
                      : () => addFavourite(resource, SET_FAV_ONDEMAND)
                  }
                >
                  <svg
                    className="demand-resource__icon"
                    style={{ fill: isFavourite ? "#3f88ae" : "none" }}
                  >
                    <use href={sprites + "#icon-heart"}></use>
                  </svg>
                  <span>
                    {isFavourite
                      ? "Remove From Favourites"
                      : "Add to Favourites"}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default OnDemand;
